export var adminApprovalDocumentTemplatePreviewer = {
  setup: function() {
    $(document).on('show.bs.modal', '.preview-approval-document-template-modal, .template-preview-for-edit', event => {
      this.setupPreviewer(event.target);
    });
  },

  setupPreviewer(modal) {
    let form = modal.closest('form');
    let recruitmentSelector = form.querySelector('.select2-selection__rendered');
    let theFrame = form.querySelector('.template-viewer-content');
    let spinner = form.querySelector('.template-preview--spinner');

    if (!theFrame.src && theFrame.dataset.src) {
      spinner.classList.remove('hidden');
      theFrame.src = theFrame.dataset.src;
    }

    function setPreviewURL() {
      let selectedJPF = recruitmentSelector.innerText.match(/JPF?\d+/i);
      if (selectedJPF) {
        let newUrl = theFrame.src.replace(/JPF?\d+/i, selectedJPF[0]);
        spinner.classList.remove('hidden');
        if (newUrl) { theFrame.src = newUrl }
      }
    }

    function setupSelect2Handlers() {
      const selector_bases = [
        '#preview_approval_document_template_editor',
        '#approval_document_template_preview_for_edit_of'
      ];

      const report_types = [
        'applicant_pool_report',
        'shortlist_report',
        'search_report',
        'search_plan'
      ];

      const combinedSelector = selector_bases
        .map(base =>
          report_types.map(type =>
            `${base}_${type}_approval_document_template_previewing_recruitment`
          ).join(',')
        )
        .join(',');

      $(combinedSelector).on('select2:select select2:unselect', (_event) => {
        setPreviewURL();
      });
    }

    setupSelect2Handlers();
    if (theFrame && spinner) { theFrame.onload = function () { spinner.classList.add('hidden'); }; }
  }
}
